import io from 'socket.io-client';
import feathers from '@feathersjs/client';
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3030';
console.log(`API_URL=${API_URL}`);
console.log(`process.env.REACT_APP_API_URL=${process.env.REACT_APP_API_URL}`);
const socket = io(API_URL);
const client = feathers();

client.configure(feathers.socketio(socket));
client.configure(feathers.authentication({
  storage: window.localStorage
}));

export { client, socket };
