import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import { Divider } from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { useTheme } from '@material-ui/core/styles';
import ClaimDialog from './ClaimDialog';

import {client, socket} from './feathers';

const styles = theme => ({
  root: {
    maxWidth: 345
  },
  media: {
    height: 180
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition:'1s'
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  }
});

class PastryCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      claimDialogOpen: false,
      claimDialogCallback: (name) => {}
    };
  }

  handleExpandClick() {
    this.setState({
      expanded: !this.state.expanded});
  };

  handleClaimClick() {
    this.setState({claimDialogOpen: true, claimDialogCallback: (name) => { this.claimDialogCallback(name); } });    
  }

  claimDialogCallback(name) {
    this.setState({claimDialogOpen: false});
    if (name) {
      var message = '';
      // TODO: Use local date/time
      var timestamp = new Date();

      (async () => {
        // TODO: Add error handling.
        await client.service('celebration').patch(this.props.celebration._id,
          { "$push" : { "cakes.$[elem].claims": {  claimer: name, message: message, timestamp: timestamp }}},
          { query: { arrayFilters: [{ "elem.cakeid": this.props.pastry._id }] } }
        )})();
    }
  }

  render () {
    const { classes } = this.props;

    // Work out which entry in the celebration we need to worry about.
    var celebrationEntry = {};
    this.props.celebration.cakes.forEach(c => {
      if (c.cakeid == this.props.pastry._id) {
        celebrationEntry = c;
      }
    });

    var quantityLeft = celebrationEntry.quantity - celebrationEntry.claims.length;
    var quantityText = "";
    if (quantityLeft == 0) {
      quantityText = "No longer available"
    } else {
      quantityText = `Items available: ${quantityLeft}`
    }

    var claims = "";

    if (celebrationEntry.claims.length > 0) {
      claims = (
        <>
          <Typography variant="body2" color="textSecondary" component="p" align="left">
            Already claimed by:
          </Typography>
          <ul>
              {celebrationEntry.claims.map(c => (
                // TODO: Sort out key.
                <li key={c.timestamp}>{c.claimer}</li>
              ))}
          </ul>
        </>
      );
    }
    var description = this.props.pastry.description;
    return (
      <Card className={classes.root}>
        <CardActionArea>
          <Typography gutterBottom variant="h5" component="h2">
            {this.props.pastry.name}
          </Typography>
          <CardMedia
            className={classes.media}
            image={this.props.pastry.url}
            title={this.props.pastry.name}
          />
          <CardContent>
            <Typography variant="body1" color="textPrimary" component="p" align="left">
              {quantityText}
            </Typography>   
            {claims}
          </CardContent>
        </CardActionArea>
        <CardActions>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: this.state.expanded,
            })}
            onClick={() => this.handleExpandClick()}
            aria-expanded={this.state.expanded}
            aria-label="show more"
                                  >
            <ExpandMoreIcon />
          </IconButton>
          <Button disabled={quantityLeft <= 0} onClick={() => this.handleClaimClick()} size="small" color="primary">
            Claim virtual {this.props.pastry.noun}
          </Button>
        </CardActions>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          {description}
        </Collapse>
        <ClaimDialog isOpen={this.state.claimDialogOpen} handleClose={this.state.claimDialogCallback}>
        </ClaimDialog>
      </Card>
    )
  }
}


export default withStyles(styles)(PastryCard);