import NavBar from './NavBar';
import React from 'react';
import HomeStartBtn from './homeStartBtn.js';
import './HomePage.css'

import MetaTags from 'react-meta-tags';


class HomePage extends React.Component {

  render() {
    return (
      <>
        <MetaTags>
          <title>VirtualPastries.com</title>
          <meta name="title" content="VirtualPastries.com" />
          <meta name="description" content="Got something to celebrate but can't make it into the office? Share virtual pastries, cakes, pies and donuts for free." />
        </MetaTags>
        <div class="background">
          <div class="jumbotron">
            <p>
              <span class="brand brand-virtual">virtual</span><span class="brand brand-pastries">pastries</span><span class="brand brand-com">.com</span>
            </p>
            <p>
              Got something to celebrate but can't make it into the office?
            </p>
            <p>
              Share virtual pastries, cakes, pies and donuts for free!
            </p>
            <p>
              <HomeStartBtn></HomeStartBtn>
            </p>
          </div>
        </div>
      </>
  )}
}

export default HomePage;