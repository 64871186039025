import React from 'react';
import logo from './logo.svg';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import './App.css';
//import {ThemeProvider} from '@material-ui/styles';

import CelebrationPage from './CelebrationPage';

import PastryList from './PastryList.js';

import CreationPage from './CreationPage.js';

import HomePage from './HomePage.js';

function App() {
  return (
    <Router>
    <div className="App">
      <Switch>
        <Route path="/celebration/:id">
          <Celebration />
        </Route>
        <Route path="/create/">
          <Creation />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </div>
    </Router>
  );
}
function Home() {
  return <HomePage></HomePage>;
}

function Celebration() {
  let { id } = useParams();
  // TODO: This could be neater.
  return <CelebrationPage id={id}></CelebrationPage>;
}
function Creation() {
  // TODO: This could be neater.
  return <CreationPage ></CreationPage>;
}
export default App;
