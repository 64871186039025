import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CreationPage from './CreationPage.js';
import './CreateDialog.css';

class CreateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textFieldValue: "",
      anonymousCheck: false
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleClaim = this.handleClaim.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleAnonymousCheckChange = this.handleAnonymousCheckChange.bind(this);
  }

  handleCancel() {
    this.props.handleClose();
    console.log(this.props.isOpen);
  }

  handleClaim() {
    this.props.handleClose(this.state.textFieldValue)
  }

  handleNameChange(e) {
    this.setState({
      textFieldValue: e.target.value
    });
  }

  handleAnonymousCheckChange(e) {
    this.setState({
      anonymousCheck: e.target.checked,
      textFieldValue: (e.target.checked) ? "Anonymous Custard" : ""
    })
  }

  render() {
    if (this.props.isOpen) {
      return (
        <div style={{alignContent:"center"}}>
          <Dialog maxWidth="sm" fullWidth open={this.props.isOpen} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">title</DialogTitle>
            <DialogContent>
              <CreationPage></CreationPage>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCancel} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

export default CreateDialog;