import React from 'react';
import PastryCard from './PastryCard';

import { Grid, GridList, GridListTile } from '@material-ui/core/'
import { withStyles } from '@material-ui/styles';

// Establish a Socket.io connection


import {client, socket} from './feathers';

// Connect to the Feathers server using the Socket.io connection
//client.configure(feathers.socketio(socket));
// Set up the Feathers authentication client
//client.configure(feathers.authentication());

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});


class PastryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        pastries: []
    };
  }

  componentDidMount() {
    console.log("Pastries did mount");

    (async () => {
      const pastries = await client.service('pastry').find({
        query: {
          $sort: { createdAt: -1 },
          $limit: 25
        }
      });
      console.log("HTTP GET: " + pastries)

      this.setState({'pastries' : pastries.data})
    })();
  }

  render() {
    const { classes } = this.props;

    return (
        <Grid container direction={'row'} className={classes.root} spacing={2}>
          {this.state.pastries.map(p => (
            <Grid item key={p._id} xs={2} sm={2} md={2} lg={2} xl={4}>
              <PastryCard img={p.url} name={p.name} description={p.description}></PastryCard>
            </Grid>
          ))}
        </Grid>
      )
  }
}

export default withStyles(styles)(PastryList);