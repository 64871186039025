import React from 'react';
import PastryCard from './PastryCard';

import { Grid, GridList, GridListTile } from '@material-ui/core/'
import { withStyles } from '@material-ui/styles';
// Establish a Socket.io connection
import MetaTags from 'react-meta-tags';
import NavBar from './NavBar';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import {client, socket} from './feathers';

// Connect to the Feathers server using the Socket.io connection
//client.configure(feathers.socketio(socket));
// Set up the Feathers authentication client
//client.configure(feathers.authentication());

const styles = theme => ({
  root: {
    flexGrow: 1
  }
  ,item: {
    flexGrow: 1,
    maxWidth:'345px !important'
  }
});


class CelebrationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        pastries: [],
        celebration: null
    };
  }

  loadData() {
    (async () => {
      const pastries = await client.service('pastry').find({
        query: {
          $sort: { createdAt: -1 },
          $limit: 25
        }
      });
      const celebration = await client.service('celebration').find({
        query: {
          _id : this.props.id,
          $limit: 1
        }
      });

      let pastryMap = {}
      pastries.data.forEach(p => {
        pastryMap[p._id] = p
      });

      this.setState({'pastries' : pastryMap});
      this.setState({'celebration' : celebration.data[0]});

    })();
  }

  componentDidMount() {
    this.loadData();

    client.service('celebration').on('patched', celebration => this.loadData());
    client.service('celebration').on('updated', celebration => this.loadData());
  }

  render() {
    const { classes } = this.props;
    if (this.state.celebration) {
      return (
          <>
          <MetaTags>//meta tags to look good when sharing
              <title>{this.state.celebration.name}</title>
              <meta name="title" content={this.state.celebration.name} />
              <meta name="description" content={"Celebrate" + this.state.celebration.name + "with them by sharing virtual pastries with them on virtualpastries.com"} />

              <meta property="og:type" content="website" />
              <meta property="og:url" content={"http://virtualpastries.com/celebration/" + this.props.id} />
              <meta property="og:title" content={this.state.celebration.name} />
              <meta property="og:description" content={"Celebrate" + this.state.celebration.name + "with them by sharing virtual pastries with them on virtualpastries.com"} />
              //<meta property="og:image" content="http://virtualpastries.com/logo-large.png" />

              <meta property="twitter:card" content="summary_large_image" />
              <meta property="twitter:url" content={"http://virtualpastries.com/celebration/" + this.props.id} />
              <meta property="twitter:title" content={this.state.celebration.name} />
              <meta property="twitter:description" content={"Celebrate" + this.state.celebration.name + "with them by sharing virtual pastries with them on virtualpastries.com"} />
              //<meta property="twitter:image" content="http://virtualpastries.com/logo-large.png" />
              <meta name="robots" content="noindex"   />
          </MetaTags>
            <NavBar></NavBar>
            <h1 style={{paddingTop:50}}>"{this.state.celebration.name}"</h1>
            <h4>(more famously known as "celebration/{this.props.id}")</h4>
            <Grid container direction={'row'} className={classes.root} spacing={2}>
              {this.state.celebration.cakes.map(c => (
                <Grid item className={classes.item} key={c.cakeid} xs={2} sm={2} md={2} lg={2} xl={4}>
                  <PastryCard pastry={this.state.pastries[c.cakeid]} celebration={this.state.celebration}></PastryCard>
                </Grid>
              ))}
            </Grid>
          </>
        )
    } else {
      return (<Backdrop open={true}>
                  <CircularProgress color="inherit" />
              </Backdrop>
             );
    }
  }
}

export default withStyles(styles)(CelebrationPage);