import React from 'react';
import { withRouter } from 'react-router-dom';

import './App.css';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import CreateDialogue from './CreateDialog.js';

class NavBar extends React.Component {
  constructor(props) {
    super(props);
      // Don't call this.setState() here!
      this.state = {
        createDialogOpen:false
    };
  
    this.routeChange = this.routeChange.bind(this);
    this.startOwnCelebration = this.startOwnCelebration.bind(this);
    this.createDialogCallback = this.createDialogCallback.bind(this);
  }
  createDialogCallback() {
    this.setState({createDialogOpen:false});
    console.log(this.state.createDialogOpen);
  }
  routeChange() {
    let path = `/login`;
    this.props.history.push(path);
    console.log(this.props.history);
  }
   
  startOwnCelebration() {
    this.setState({createDialogOpen:true});
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton edge="start" className="" color="inherit" aria-label="menu">
            <MenuIcon />
            </IconButton>
            <img src="/virtualpastries.svg" alt="virtualpastries.com" />
            <div style={{ flex: 1 }}></div>
            Start your own digital celebration here: <div style={{ padding: 10 }}></div><Button variant="contained" color="secondary" onClick={this.startOwnCelebration}>START</Button>
          </Toolbar>
        </AppBar>
        <CreateDialogue  isOpen={this.state.createDialogOpen} handleClose={this.createDialogCallback}></CreateDialogue>
      </>
    );
  }
}

export default withRouter(NavBar);
